/**
 * @see https://medium.com/@keeganfamouss/accessibility-on-demand-with-chakra-ui-and-focus-visible-19413b1bc6f9
 */
import "focus-visible/dist/focus-visible";

import { GatsbyBrowser } from "gatsby";
import React from "react";
import GlobalSeo from "src/components/GlobalSeo";

export const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({
  element,
}) => element;

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}) => (
  <>
    {element}

    <GlobalSeo />
  </>
);

export const onClientEntry: GatsbyBrowser["onClientEntry"] = async () => {};

export const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = () => {};
