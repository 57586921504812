import Card from "./Card";
import Container from "./Container";
import Heading from "./Heading";
import Text from "./Text";

export default {
  Card,
  Container,
  Text,
  Heading,
};
