import { ComponentStyleConfig } from "@chakra-ui/react";

const Text: ComponentStyleConfig = {
  baseStyle: {},

  sizes: {},

  variants: {
    caption: {
      color: "gray.500",
      fontSize: "xs",
      textTransform: "uppercase",
    },
  },

  defaultProps: {},
};

export default Text;
