import { ComponentStyleConfig } from "@chakra-ui/react";

const Card: ComponentStyleConfig = {
  baseStyle: {
    bg: "white",
    transitionProperty: "common",
    transitionDuration: "normal",
    overflow: "hidden",

    shadow: "md",

    _hover: {
      shadow: "lg",
    },
  },

  sizes: {
    md: {
      p: { base: 4, md: 6 },
      borderRadius: "md",
    },
    lg: {
      p: { base: 6, md: 8, lg: 10 },
      borderRadius: "lg",
    },
  },

  variants: {},

  defaultProps: {
    size: "md",
  },
};

export default Card;
