import { ComponentStyleConfig } from "@chakra-ui/react";

const Heading: ComponentStyleConfig = {
  baseStyle: {
    fontWeight: "normal",
  },

  sizes: {
    lg: {
      fontSize: "2xl",
    },
  },

  variants: {},

  defaultProps: {},
};

export default Heading;
