import { ComponentStyleConfig } from "@chakra-ui/react";

const Container: ComponentStyleConfig = {
  baseStyle: {},

  sizes: {},

  variants: {},

  defaultProps: {},
};

export default Container;
