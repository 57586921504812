import { extendTheme } from "@chakra-ui/react";

import components from "./components";
import foundations from "./foundations";

const theme = extendTheme({
  components,
  foundations,
  fonts: {
    heading: "Suez One",
  },
  styles: {
    global: {
      ".chakra-heading strong, .chakra-heading b": {
        fontWeight: "normal",
      },
    },
  },
});

export default theme;
