import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";

type FavIconMetaTag = {
  tagName: string;
  attributes: {
    rel: string;
    sizes: string;
    href: string;
  };
};

const GlobalSeo = () => {
  const { datoCmsSite } = useStaticQuery<Gatsby.GlobalSeoQuery>(graphql`
    query GlobalSeo {
      datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          titleSuffix

          fallbackSeo {
            title
            description
            image {
              gatsbyImageData(width: 1200)
            }
          }
        }
      }
    }
  `);

  const { globalSeo, faviconMetaTags } = datoCmsSite!;

  if (!globalSeo || !faviconMetaTags) throw Error();

  return (
    <Helmet
      defaultTitle={globalSeo.fallbackSeo?.title}
      htmlAttributes={{
        lang: "fr",
      }}
      meta={[
        { name: "description", content: globalSeo.fallbackSeo?.description },
      ]}
      titleTemplate={`%s${globalSeo.titleSuffix}`}
    >
      {faviconMetaTags.tags.map(
        ({ tagName: Component, attributes }: FavIconMetaTag) => (
          <Component
            key={`${attributes.rel}-${attributes.sizes}-${attributes.href}`}
            {...attributes}
          />
        )
      )}
    </Helmet>
  );
};

export default GlobalSeo;
